import React, { useState } from 'react';
import prime from "../Json/JsonDaata";
import  { useLayoutEffect } from 'react'
import { Image } from 'antd';
import "./gallery.css"
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import galleryIMG from "../../Assets/mountain terrace.jpg"
function GalleryP() {
  const [items, setItems] = useState(prime);


  const filterItems = (category) => {
    if (category === 'All') {
      setItems(prime);
    } else {
      const updatedItems = prime.filter((item) => item.category === category);
      setItems(updatedItems);
    }
  };

  return (
    <>

    <div className="hide-dd">
    Discover the "Best Villa in Lonavala" for an unparalleled getaway experience. Nestled amidst the lush greenery and serene hills, our villa offers luxurious amenities and breathtaking views. Whether you're planning a family vacation or a romantic escape, this villa promises comfort and elegance. Enjoy spacious rooms, a private pool, and modern facilities that ensure a relaxing stay. Conveniently located near popular attractions, the "Best Villa in Lonavala" is the perfect retreat to unwind and rejuvenate. Book your stay now to experience the ultimate blend of nature and luxury.
    </div>
      <section
      className="page-title"
      style={{
        backgroundImage: `linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ), url("${galleryIMG}")`,
      }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Gallery</h1>
        </div>
      </div>
    </section>
    
     
  
    <div className="container">



      <section className="myGallery">
        <div className="titleSection">
          <h3>Browse our Gallery</h3>
        </div>
        <div className="filters filter-button-group">
          <ul>
            <li className="active">
              <a href="#all" onClick={() => filterItems('All')}>All</a>
            </li>
            <li>
              <a href="#room" onClick={() => filterItems('room')}>Room</a>
            </li>
            <li>
              <a href="#swimmingpool" onClick={() => filterItems('Swimmingpool')}>Swimming Pool</a>
            </li>
            <li>
              <a href="#events" onClick={() => filterItems('events')}>Events</a>
            </li>
            <li>
              <a href="#activities" onClick={() => filterItems('activity')}>Activities</a>
            </li>
          </ul>
        </div>

        {/* <div className="row">
          {items.map((item, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4 col-xl-3 hero" style={{ marginBottom: "20px" }}>
              <div id="containergallery" className="isotope" style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="grid-item" data-filter={item.category} data-page={1} style={{ left: 0, top: 0 }}>
                
  <Image
    
    src={item.imageUr1} alt="" style={{ marginBottom: "10px", maxWidth: "100%", alignSelf: "center" }} />
    <div className="overlay">{item.title}</div>

                  
                </div>
              </div>
            </div>
          ))}
        </div> */}

         <Box>
            <ImageList variant="masonry" cols={4} gap={8}>
              {items.map((item) => (
                <ImageListItem key={item.img}>
                  <Image
                    srcSet={`${item.imageUr1}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item.imageUr1}?w=248&fit=crop&auto=format`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>

{/* <Box>
      <ImageList variant="masonry" cols={4} gap={8}>
        {items.map((item) => (
          <ImageListItem key={item.img}>
            <Image
              srcSet={`${item.imageUr1}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.imageUr1}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box> */}
      </section>
    </div>
    </>
  );
}

export default GalleryP;
