import React, { useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./navi.css";
import logo from "../../Assets/Prime Villa-lllooo.png";
import { Link } from 'react-router-dom';

function Navi() {
  const [expanded, setExpanded] = useState(false);
  const collapseRef = useRef(null);

  const handleToggle = () => {
    setExpanded(expanded ? false : "expanded");
  };

  const closeNavbar = () => {
    setExpanded(false);
  };

  return (
    <Navbar expanded={expanded} onToggle={handleToggle} expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">
          <div className="imgg">
            <img src={logo} alt="" />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="basic-navbar-nav" ref={collapseRef}>
          <Nav className="cent-re">
            <Nav.Link as={Link} to="/" onClick={closeNavbar}>HOME</Nav.Link>
            <Nav.Link as={Link} to="/aboutpage" onClick={closeNavbar}>ABOUT</Nav.Link>
            <Nav.Link as={Link} to='/activities' onClick={closeNavbar}>ACTIVITY</Nav.Link>
            <Nav.Link as={Link} to="/gallery" onClick={closeNavbar}>GALLERY</Nav.Link>
            <Nav.Link as={Link} to="/rooms" onClick={closeNavbar}>ROOM</Nav.Link>
            <Nav.Link as={Link} to="/attrac" onClick={closeNavbar}>ATTRACTION</Nav.Link>
            <Nav.Link as={Link} to="/contactpage" onClick={closeNavbar}>CONTACT</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navi;
