import React, { useState } from 'react';
import { CiCircleCheck } from 'react-icons/ci';
import aboutbanneer from '../Assets/parking-.jpg';
import Counter from '../Components/Counter/Counter';
import Footer from '../Components/Footer/Footer';
import whych from '../Assets/Pool/p-room.jpg';
import whyIMG from '../Assets/AboutImages/mountain-view.jpeg';
import poolview from '../Assets/Pool/pool-view.jpg';
import aboutwside from '../Assets/parking-.jpg';
import storyImage from '../Assets/terris view.jpg';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';

function AboutPage() {
  const [showAllParagraphs, setShowAllParagraphs] = useState(false);

  const toggleParagraphs = () => {
    setShowAllParagraphs(!showAllParagraphs);
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Title */}
        <section
          className="page-title"
          style={{
            backgroundImage: `linear-gradient(
                              rgba(0, 0, 0, 0.5), 
                              rgba(0, 0, 0, 0.5)
                            ), url("${aboutbanneer}")`,
          }}
        >
          <div className="auto-container">
            <div className="text-center">
              <h1>About</h1>
            </div>
          </div>
        </section>

        <section className="welcome-section-six light-bg mx-60 border-shape-top">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-6">
                <div className="title-box">
                  <div className="sub-title">welcome to bluebell</div>
                  <h2 className="sec-title small mb-30">
                    Our Villa has been <br />
                    present for over 20 years.
                  </h2>
                  <div className="text">
                    We aim to provide the best service to all our customers.
                  </div>
                  <div className="text-two">
                    Nestled in the serene and picturesque landscapes of Lonavala, our villa has been a cornerstone of comfort and luxury for over 11 years. Renowned as the Best Villa For Family in Lonavala, it offers an unparalleled experience for families seeking a tranquil retreat from the hustle and bustle of city life.
                  </div>
                  {showAllParagraphs && (
                    <>
                      <div className="text-three">
                        From the moment you step into our villa, you are enveloped in an ambiance of warmth and elegance. The spacious and meticulously designed interiors provide the perfect setting for family gatherings, celebrations, and creating unforgettable memories. Each room is thoughtfully furnished to ensure utmost comfort, making it a true home away from home.
                      </div>
                      <div className="text-three">
                        The villa’s prime location in Lonavala allows families to explore the natural beauty and attractions of the area with ease. Whether it's a leisurely stroll through the lush gardens, a refreshing dip in the private pool, or enjoying a cozy evening by the fireplace, our villa caters to every need and desire.
                      </div>
                      <div className="text-three">
                        Over the past 11 years, we have continuously upgraded our amenities to offer modern conveniences while retaining the charm and character that make our villa unique. This dedication to excellence has solidified our reputation as the Best Villa for Family in Lonavala, making it a cherished destination for families year after year.
                      </div>
                    </>
                  )}
                 
                  <div className="author-info">
                    <div className="author-wrap">
                      <div className="author-thumb">
                        <img src="assets/images/resource/image-7.png" alt="" />
                      </div>
                      <div className="name">Vikram Baburao Bhosale</div>
                      <div className="designation">Villa Manager </div>
                    </div>
                    <div className="signature">
                      <img src="assets/images/resource/image-13.png" alt="" />
                    </div>
                  </div>
                  <div className="buttonGroup" style={{marginTop:"10px"}}>
                    <button onClick={toggleParagraphs}>
                    {showAllParagraphs ? <FaAngleUp /> : <FaAngleDown />}&nbsp;{showAllParagraphs ? 'Read Less' : 'Read More'}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="text-lg-end">
                  <div className="image-side">
                    <img src={aboutwside} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Why Choose Us */}
        <section className="why-choose-us-section light-bg mx-60 pt-0 border-shape-bottom">
          <div className="auto-container">
            <div className="title-box text-center">
              <div className="sub-title">Our Features</div>
              <h2 className="sec-title mb-30">Why Choose Us</h2>
              <div className="text">
                We offer unparalleled expertise and personalized solutions to meet your unique needs.
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 why-choose-us-block">
                <div className="image">
                  <img src={whyIMG} alt="" />
                </div>
                <div className="inner-box">
                  <div className="image-block">
                    <img src={whych} alt="" />
                  </div>
                  <div className="text-three">
                    <span>1350 +</span> Visiters <br /> Come Annunally
                  </div>
                </div>
              </div>
              <div className="col-lg-6 why-choose-us-block">
                <div className="text-two">
                  Our commitment to excellence and customer satisfaction sets us apart in the industry.
                </div>
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      Pvt parking area
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      A/C
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      Wi-Fi
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      Game zone
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      TV
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      Pvt swimming pool
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      {/* 24/7 care at your place */}
                      Round-Clock Care
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      {/* 24/7 care at your place */}
                      Family Room
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      {/* 24/7 care at your place */}
                      Room Service
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      {/* 24/7 care at your place */}
                      Pets are allowed
                    </li>
                    <li>
                      <i className="flaticon-checkmark"><CiCircleCheck /></i>
                      {/* 24/7 care at your place */}
                      City View
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* about section two */}
        <section className="about-section-two dark_bg">
          <div className="auto-container">
            <div className="row">
              <div className="col-lg-7">
                <div className="title-text">
                  <div className="sub-title">about us</div>
                  <h2 className="sec-title text-light">
                    The Story of Behind <br /> Our Villa
                  </h2>
                </div>
                <div className="text">
                  Nestled amidst the serene hills of Lonavala, our villa stands out as the best villa for Family in Lonavala. Designed with families in mind, this charming retreat offers spacious rooms, a fully equipped kitchen, and a cozy living area. The lush garden and private pool provide endless entertainment for both kids and adults. Located close to popular attractions like Bhushi Dam and Tiger's Leap, it ensures easy access to adventure and relaxation alike. Whether it's a weekend getaway or a summer vacation, Our Villa promises an unforgettable stay, making it the ideal family destination in Lonavala.
                </div>
                <div className="image">
                  <img src={storyImage} alt="" />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="image-twoo">
                  <img src={poolview} alt="" />
                </div>
                <div className="text-two">
                  Nestled among the tranquil hills of Lonavala, our villa is renowned as the premier family retreat in the area. Crafted with families in mind, this delightful sanctuary features expansive accommodations, a fully equipped kitchen, and a welcoming living space. The verdant garden and exclusive pool offer endless amusement for both children and adults alike. Situated near renowned landmarks such as Bhushi Dam and Tiger's Leap, our villa ensures effortless access to both thrilling adventures and soothing relaxation. Whether you're planning a brief weekend escape or a leisurely summer retreat, Our Villa guarantees an unforgettable experience, making it the ultimate family destination in Lonavala.
                </div>
                {showAllParagraphs && (
                  <div className="buttonGroup">
                    <button onClick={toggleParagraphs}>
                      Read Less
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* Our Team */}
        <Counter />
        <Footer />
        <div className="light-bg mx-60">
          <div className="auto-container">
            <div className="boder-bottom-two" />
          </div>
        </div>

      </div>

      <div
        className="scroll-to-top scroll-to-target"
        data-target="html"
        style={{ display: "none" }}
      >
        <span className="fas fa-arrow-up" />
      </div>
    </>
  );
}

export default AboutPage;
