import React from 'react'

function Restaurant() {
  return (
    <>
      <section className="service-section light-bg mx-60 border-shape-top">
  <div className="auto-container">
    <div className="row">
      <div className="col-lg-6">
        <div className="image-block">
          <div className="image">
            <img src="https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-7.jpg" alt="" />
          </div>
          <div className="text-end">
            <div className="image-two img_hover_1">
              <img src="https://html.tonatheme.com/2023/bluebell/assets/images/resource/image-36.jpg" alt="" />
            </div>
          </div>
          <div className="text-one">Fresh food</div>
          <div className="text-two">restaurant</div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="content-block">
          <div className="title-box">
            <div className="sub-title">eat &amp; drink</div>
            <h2 className="sec-title small">
              {" "}
              Indulge in exceptional <br /> &amp; Local Foodies
            </h2>
          </div>
          <div className="time">Lorem ipsum dolor sit amet. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Adipisci, suscipit i ste numquam consectetur tempora fuste numquam consectetur tempora fuste numquam consectetur tempora fugiat.</div>
          <div className="link-btn">
            <a href="restaurant.html" className="theme-btn btn-style-two">
              <span>Read more</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Restaurant
