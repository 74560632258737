import room1 from "../../Assets/Rooms/20230603_160611.jpg"
import room2 from "../../Assets/Rooms/4-bedroom.jpeg"
import room3 from "../../Assets/Rooms/5-bedroom.jpeg"
import room4 from "../../Assets/Rooms/bed-down-view.jpg"
import room5 from "../../Assets/Rooms/bed.jpg"
import room6 from "../../Assets/Rooms/view2.jpg"



import swimming1 from "../../Assets/Pool/pool-.jpg"
// import swimming2 from "../../Assets/Pool/pool-top-r.jpg"
// import swimming4 from "../../Assets/Pool/pool-view.jpg"
import swimming9 from "../../Assets/Pool/top view swimming poool.jpg"
import swimming10 from "../../Assets/Banners/AnyConv.com__prvt-pool.jpg"




import activity2 from "../../Assets/Game/carram.jpg"
import activity3 from "../../Assets/Game/table=pool.jpeg"
import activity5 from "../../Assets/Game/hand-football.jpg"
import activity6 from "../../Assets/Game/table-ppol.jpg"


// import event1 from "../Assets/eventHoli.jpg"
// import event2 from "../Assets/eventfood.jpg"
// import event3 from "../Assets/eventchild.jpg"
// import event4 from "../Assets/eventwoffel.jpg"


const prime = [
// ********************************************************************************************
      {
          id: 1,
          title: "A/C Room",
          category:"room",
          imageUr1: room1
          
          
        },
      {
          id: 2,
          title: "A/C Room",
          category:"room",
          imageUr1: room2,
          width: 4,
         height: 3,
         
          
          
        },
      {
          id: 3,
          title: "A/C Room",
          category:"room",
          imageUr1: room3           
          
          
        },
      {
          id: 4,
          title: "A/C Room",
          category:"room",
          imageUr1: room4          
          
          
        },
      {
          id: 5,
          title: "A/C Room",
          category:"room",
          imageUr1: room5          
          
          
        },
      // {
      //     id: 6,
      //     title: "A/C Room",
      //     category:"room",
      //     imageUr1: room6          
          
          
      //   },
     
        // ******************************************swimming************************************************************

       

        {
          id:1,
          title: "Swimmingpool",
          category:"Swimmingpool",
          imageUr1: swimming1,
          width: 4,
          height: 3,
        },
    //     {
    //       id:2,
    //       title: "Swimmingpool",
    //       category:"Swimmingpool",
    //       imageUr1: swimming2,
    //       width: 1,
    // height: 1,
    //     },

        // {
        //   id:3,
        //   title: "Swimmingpool",
        //   category:"Swimmingpool",
        //   imageUr1:swimming4,
        //   width: 3,
        //   height: 4,
        // },
        {
          id:4,
          title: "Swimmingpool",
          category:"Swimmingpool",
          imageUr1: swimming9,
          width: 1,
          height: 2,
          
        },
        {
          id:5,
          title: "Swimmingpool",
          category:"Swimmingpool",
          imageUr1: swimming10,
          width: 1,
          height: 2,
          
        },

        // **************************************************event*******************************************************
        {
          id:1,
          title: "Events",
          category:"events",
          imageUr1: "https://image.wedmegood.com/resized/540X/uploads/member/2078684/1625828570_IMG20210502144140.jpg",
          width: 3,
          height: 4,
          
        },
        {
          id:2,
          title: "Events",
          category:"events",
          imageUr1: "https://content.jdmagicbox.com/comp/mumbai/e7/022pxx22.xx22.200322135013.l3e7/catalogue/alibaba-banquet-jogeshwari-west-mumbai-banquet-halls-g68f52fsq3.jpg",
          width: 4,
          height: 3,
        
        },
        {
          id:3,
          title: "Events",
          category:"events",
          imageUr1: "https://media.weddingz.in/images/1e83bc4654509eb46e7b04a99078fd10/alibaba-banquet-hall-alibaba-banquet-hall-4.jpg",
          width: 2,
          height: 2,
        },
        {
          id:4,
          title: "Events",
          category:"events",
          imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTLpVivjCljGnJk9kyWE_YVnNx7Q2l1nVYgbELYMjHEj7218fhHr3E8roFPyK8BqLoHZI&usqp=CAU",
          width: 4,
          height: 4,
        },
        {
          id:5,
          title: "Events",
          category:"events",
          imageUr1:"https://5.imimg.com/data5/SELLER/Default/2023/11/363389738/TN/JU/MW/51070040/birthday-party-event-services.png",
          width: 4,
          height: 3,
        },
        {
          id:6,
          title: "Events",
          category:"events",
          imageUr1:"https://content.jdmagicbox.com/comp/mumbai/a8/022pxx22.xx22.190802110006.v3a8/catalogue/g-art-chembur-east-mumbai-event-organisers-for-birthday-party-hudxydne5z.jpg?clr=",
          width: 3,
          height: 1,
        },
        {
          id:7,
          title: "Events",
          category:"events",
          imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTLpVivjCljGnJk9kyWE_YVnNx7Q2l1nVYgbELYMjHEj7218fhHr3E8roFPyK8BqLoHZI&usqp=CAU",
          width: 4,
          height: 4,
        },
        {
          id:8,
          title: "Events",
          category:"events",
          imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7lI2na3yfWcDyTte_1Gf2QHT-aiG8CTPGuw&shttps://content.jdmagicbox.com/comp/mumbai/a8/022pxx22.xx22.190802110006.v3a8/catalogue/g-art-chembur-east-mumbai-event-organisers-for-birthday-party-hudxydne5z.jpg?clr=",
          width: 2,
          height: 4,
        },
      

        // ***************************************************Activity*************************************************************************
        {
          id:1,
          title: "Activities",
          category:"activity",
          imageUr1: activity2,
          width: 1,
          height: 1,
        },
  
        {
          id:2,
          title: "Activities",
          category:"activity",
          imageUr1:activity3,
          width: 3,
          height: 4,
        },
  
        {
          id:3,
          title: "Activities",
          category:"activity",
          imageUr1:activity5  ,
          width: 3,
          height: 2,
          
        },
        {
          id:4,
          title: "Activities",
          category:"activity",
          imageUr1: activity6,
          width: 3,
          height: 4,
          
        }
  
]
export default prime

