import React from 'react'
import Banner from '../Banner/Banner'
import HomeAbout from '../HomeAbout/HomeAbout'
import Amenities from '../AMENITIES/Amenities'
import Accomodation from '../ACCOMODATION/Accomodation'
import Restaurant from '../RESTAURANT/Restaurant'
import Testimonials from '../Testimonials/Testimonials'
import Footer from '../Footer/Footer'

function Home() {
  return (
    <>
      <Banner/>
      <HomeAbout/>
      <Amenities/>
      <Accomodation/>
      {/* <Restaurant/> */}
      <Testimonials/>
      <Footer/>
    </>
  )
}

export default Home
