import React from 'react'
import { IoCall } from "react-icons/io5";
import Footer from '../Components/Footer/Footer';
import { Button, Form, Input } from "antd";
import $ from "jquery"
function ContactPage() {
  const submitForm = (values) => {
    console.log("values",values);

    var body =  '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#dc9d7e">Prime Vill</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#dc9d7e"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Email:</strong></td><td style="text-align:left">' + values.email + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Subject:</strong></td><td style="text-align:left">' + values.subject + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Message:</strong></td><td style="text-align:left">' + values.message + '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Mobile Number:</strong></td><td style="text-align:left">' + values.mobileNo + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Prime Villa</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: " primevilla07@gmail.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "Prime Villa",
            accountLeadSource: "primevilla.in",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);

            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        values.preventDefault()
        return false;
  };
  return (
    <>
      <>
    <div className="hide-dd">
    Looking for a perfect getaway? Our villa near Lonavala offers the ideal retreat with serene views and luxurious amenities. Whether you're planning a family vacation or a romantic escape, our Villa Near Lonavala is designed to cater to your every need. Feel free to reach out to us for more information or to make a booking. We're here to assist you with any inquiries and ensure your stay is memorable. Contact us today to experience the best in comfort and relaxation. We look forward to welcoming you to our exquisite villa.
    </div>
  <div className="page-wrapper">

    <div id="search-popup" className="search-popup">
      <div className="close-search theme-btn">
        <i className="far fa-times" />
      </div>
      <div className="popup-inner">
        <div className="overlay-layer" />
        <div className="search-form">
          <form method="post" action="index.html">
            <div className="form-group">
              <fieldset>
                <input
                  type="search"
                  className="form-control"
                  name="search-input"
                  defaultValue=""
                  placeholder="Search Here"
                  required=""
                />
                <input
                  type="submit"
                  defaultValue="Search Now!"
                  className="theme-btn"
                />
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </div>
    {/* Page Title */}
    <section
      className="page-title"
      style={{
        backgroundImage: "url(https://www.shutterstock.com/image-photo/website-page-contact-us-email-600nw-1958537320.jpg)"
      }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Contact Us</h1>
        </div>
      </div>
    </section>
    {/* Contact Form section */}
    <section className="contact-form-section light-bg mx-60 border-shape-top border-shape-bottom">
      <div className="auto-container">
        <div className="sec-title">
          <div className="sub-title">get in touch</div>
          <h2>Drop a message for any querry</h2>
          <div className="text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. <br /> Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis, nesciunt!
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            {/*Contact Form*/}
            <div className="contact-form style-two">
              {/* <form id="contact-form"> */}
                <div className="row">
                <Form
                className="php-email-form"
                layout="vertical"
                onFinish={submitForm}
              >
                <div className="row">
                  <div className="form-group col-md-6">
                    <Form.Item 
                    name="name" 
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your name!',
                      },
                    ]}
                    >
                      <Input
                        placeholder="Enter a name"
                        className="form-control custName custNameValidation"
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group col-md-6">
                    <Form.Item 
                    name="subject" 
                    label="Subject"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter subject!',
                      },
                    ]}
                    >
                      <Input
                        placeholder="Enter subject"
                        className="form-control custPhone custPhoneValidation"
                      />
                    </Form.Item>
                  </div>
                <div className="form-group col-lg-6">
                  <Form.Item 
                  name="email"
                   label="Email"
                   rules={[
                    {
                      required: true,
                      message: 'Please enter email!',
                    },
                  ]}
                   >
                    <Input
                      placeholder="Enter e-mail id"
                      className="form-control custEmail custEmailValidation"
                    />
                  </Form.Item>
                </div>
                <div className="form-group col-lg-6">
                  <Form.Item 
                  name="mobileNo"
                   label="mobileNo"
                   rules={[
                    {
                      required: true,
                      message: 'Please enter mobile:no!',
                    },
                  ]}
                   >
                    <Input
                      placeholder="Enter mobile:no"
                      className="form-control custEmail custEmailValidation"
                    />
                  </Form.Item>
                </div>
                </div>
                <div className="form-group">
                  <Form.Item
                   name="message"
                    label="Message"
                    rules={[
                      {
                        required: true,
                        message: 'Please Your message!',
                      },
                    ]}
                    >
                    <Input.TextArea
                      className="form-control custMessage"
                      placeholder="Enter message"
                    />
                  </Form.Item>
                </div>
                <div className="text-center cutomSubmit">
                  <Form.Item>
                    <Button htmlType="submit">Send Message</Button>
                  </Form.Item>
                </div>
              </Form>
                </div>
              {/* </form> */}
            </div>
          </div>
          <div className="col-lg-4 icon_box">
            <div className="inner-box">
              <h4>Prime Villa</h4>
              <div className="border-shap" />
              <div className="text-two">
              Plot no 9, Dam Road, near Patel Farm, near Asha Bhosale Bungalow, Gold Valley Sector D,   
                <br />
                Tungarli, Lonavala, Maharashtra 410403
              </div>
              <div className="text-three">
                <a href="mailto:contact@bluebell.com">primevilla07@gmail.com</a>
              </div>
            </div>
            <div className="icon-box">
              <h4>Reception Phone No.</h4>
              <div className="icon">
                <h5>
                  <i><IoCall /></i>
                  <a href="tel:18004567890">8108559115</a>
                </h5>
              </div>
              <div className="text-four">
                Check in: 12.00 PM <br />
                Check out: 11.30 AM
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Map Section */}
    <section className="map-section-two mx-60">
      <div className="auto-container">
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3777.612719690812!2d73.40581697519781!3d18.770828182371844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDQ2JzE1LjAiTiA3M8KwMjQnMzAuMiJF!5e0!3m2!1sen!2sin!4v1717585752268!5m2!1sen!2sin"
            width={600}
            height={500}
            frameBorder={0}
            style={{ border: 0, width: "100%" }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex={0}
          />
          
        </div>
      </div>
    </section>
    {/*footer section  */}

  </div>
  {/*End pagewrapper*/}
  {/*Scroll to top*/}
  <div
    className="scroll-to-top scroll-to-target"
    data-target="html"
    style={{ display: "none" }}
  >
    <span className="fas fa-arrow-up" />
  </div>

  <Footer/>
</>

    </>
  )
}

export default ContactPage
