import React from 'react'
import roomlast from "../../Assets/Rooms/4-bedroom.jpeg"
import room5 from "../../Assets/Rooms/sidebedview.jpg"
import room4 from "../../Assets/Rooms/5-bedroom.jpeg"
import room3 from "../../Assets/Rooms/bed.jpg"
import room2 from "../../Assets/Rooms/bed-down-view.jpg"
import "./acco.css"
function Accomodation() {
  return (
    <>
      <section className="room-section">
  <div className="auto-container">
    <div className="top-content">
      <div className="title-box text-light">
        <div className="sub-title">Accomodation</div>
        <h2 className="sec-title">Luxurious 4 BHK Room</h2>
      </div>
      <div className="right-column">
        <div className="text">
          {/* Lorem, ipsum dolor sit amet consectetur adipisicing. <br /> Lorem ipsum dolor sit amet consectetur. */}
        </div>
        {/* <div className="link-btn">
          <a href="room-grid.html" className="view-all-btn">
            <span>View All Rooms</span>
          </a>
        </div> */}
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4 room-block">
        <div className="inner-box">
          <div className="img-acco">
            <img src={room2} alt="" />
          </div>
          <div className="content">
            
          </div>
        </div>
      </div>
      <div className="col-lg-4 room-block">
        <div className="inner-box">
          <div className="img-acco">
            <img src={room3} alt="" />
          </div>
          <div className="content">
            <h3>
            </h3>
          </div>
        </div>
      </div>
      <div className="col-lg-4 room-block">
        <div className="inner-box">
          <div className="img-acco">
            <img src={room4} alt="" />
          </div>
          <div className="content">
            <h3>
              {/* <a href="booking-reservation.html"> Room with window</a> */}
            </h3>
            {/* <div className="text-two">₹18000 /per night for 1 villa </div> */}
          </div>
        </div>
      </div>
      <div className="col-lg-4 room-block">
        <div className="inner-box">
          <div className="img-acco">
            <img src={room5} alt="" />
          </div>
          <div className="content">
          </div>
        </div>
      </div>
      <div className="col-lg-8 room-block">
        <div className="inner-box">
          <div className="img-acco-do  ">
            <img src={roomlast} alt="" />
          </div>
          <div className="content">

            <ul>
              <li>FULLY A/C Room</li>
              <li>Room with window</li>
              <li>₹18000 /per night for 1 villa</li>
             
            </ul>
          </div>
        </div>
        <div className="mor">
          <button><a href="/gallery">view more</a></button>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Accomodation
