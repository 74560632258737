import React from 'react'

function Counter() {
  return (
    <>
 

      <section className="funfact-section light-bg mx-60">
        
  <div className="container counter-cent">
   
    <div className="row">
      
      <div className="col-lg-3 funfact-block">
        <div className="count-outer count-box counted">
          <span className="count-text" data-speed={3000} data-stop={30}>
            250
          </span>
          <span className="plus">+</span>
        </div>
        <div className="text-center">Booking Monthly</div>
      </div>
      <div className="col-lg-3 funfact-block">
        <div className="count-outer count-box counted">
          <span className="count-text" data-speed={3000} data-stop={30}>
            30
          </span>
          <span className="plus">+</span>
        </div>
        <div className="text-center">Visitors daily</div>
      </div>
      <div className="col-lg-3 funfact-block">
        <div className="count-outer count-box counted">
          <span className="count-text" data-speed={3000} data-stop={98}>
            98
          </span>
          <span className="plus">%</span>
        </div>
        <div className="text-center">Positive feedback</div>
      </div>
      <div className="col-lg-3 funfact-block">
        <div className="count-outer count-box counted">
          <span className="count-text" data-speed={3000} data-stop={20}>
            20
          </span>
          <span className="plus">+</span>
        </div>
        <div className="text-center">Awards &amp; honors</div>
      </div>
    </div>
    </div>
</section>

    </>
  )
}

export default Counter
