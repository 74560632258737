import React from 'react'
import Footer from '../Components/Footer/Footer'
import TablePool from "../Assets/Game/table-ppol.jpg"
import foosball from "../Assets/Game/hand-football.jpg"
import carrom from "../Assets/Game/carram.jpg"
import aboutpagebanner from "../Assets/AboutImages/mountain-view.jpeg"
import bat from "../Assets/badminton-grass-racket-115016.webp"
function Activities() {
  return (  
      <>
  <div className="page-wrapper">

    {/*Search Popup*/}
    <div id="search-popup" className="search-popup">
      <div className="close-search theme-btn">
        <i className="far fa-times" />
      </div>
      <div className="popup-inner">
        <div className="overlay-layer" />
        <div className="search-form">
          <form method="post" action="index.html">
            <div className="form-group">
              <fieldset>
                <input
                  type="search"
                  className="form-control"
                  name="search-input"
                  defaultValue=""
                  placeholder="Search Here"
                  required=""
                />
                <input
                  type="submit"
                  defaultValue="Search Now!"
                  className="theme-btn"
                />
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </div>
    {/* Page Title */}
    <section
      className="page-title"
      style={{
       backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.5), 
        rgba(0, 0, 0, 0.5)
      ), url("${aboutpagebanner}")`,
      }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Activities</h1>
        </div>
      </div>
    </section>
    {/* activities section */}
    <section className="activities-section light-bg mx-60 border-shape-top">
      <div className="auto-container">
        <div className="sub-title">Service since 2010</div>
        <h2 className="sec-title">We Provide Lots of Activity In Resort</h2>
        <div className="top-text mb_40">
          <div className="row">
            <div className="col-lg-6">
              <div className="text">
              Prime Villa is the perfect getaway for those seeking a luxurious retreat. Located in the picturesque town of Lonavala, this villa offers an unparalleled experience with its exquisite amenities and serene surroundings. Prime Villa stands out as a premier option for travelers searching for a "villa in Lonavala with Pool." The private pool provides a refreshing escape, allowing guests to unwind and enjoy the scenic beauty of Lonavala
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ps-lg-5 pe-lg-5">
                <div className="text">
                Inside, the villa boasts spacious, elegantly designed interiors equipped with modern amenities to ensure a comfortable stay. Whether you are planning a family vacation or a weekend with friends, this "Villa in Lonavala with Pool" caters to all your needs. The lush garden surrounding the pool offers a tranquil spot for relaxation, making Prime Villa an ideal choice for anyone looking for a "Villa in Lonavala with Pool." Book your stay today for an unforgettable experience.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-lg-6">
            <div className="inner-box">
              <div className="image-acti">
                <img src="https://5.imimg.com/data5/SELLER/Default/2023/10/353083896/MK/LB/TT/7956182/football-base-construction-with-drainage-500x500.jpeg" alt="" />
              </div>
              <div className="lower-contant">
                <div className="text-two">Outdoor Game</div>
                <h5>Football</h5>
                <div className="text-three">
                Football is a dynamic and globally beloved sport that combines strategy, skill, and teamwork, captivating millions of fans with its exhilarating matches and storied rivalries.
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-6">
            <div className="inner-box">
              <div className="image-acti">
                <img src={TablePool} alt="" />
              </div>
              <div className="lower-contant">
                <div className="text-two">Indoore Game</div>
                <h5>Pool Table</h5>
                <div className="text-three">
                Played for cue sports like snooker, eight-ball, and billiards, a pool table is a rectangular surface with six pockets that is usually covered with green felt
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="inner-box">
              <div className="image-acti">
                <img src="https://images.offerup.com/RwEJOcdeeGSUPT-lECTRNqdVQ5M=/1440x1920/612e/612e18c6097848d8b45fe2015955540b.jpg" alt="" />
              </div>
              <div className="lower-contant">
                <div className="text-two">Indoor Game</div>
                <h5>Table Tennis</h5>
                <div className="text-three">
                Table tennis, also known as ping pong, is a fast-paced sport played on a rectangular table divided by a net, where players use small paddles to hit a lightweight ball back and forth.
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-lg-6">
            <div className="inner-box">
              <div className="image-acti">
                <img src={carrom} alt="" />
              </div>
              <div className="lower-contant">
                <div className="text-two">Indoor Game</div>
                <h5>Carrom Board</h5>
                <div className="text-three">
                A carrom board is a square, smooth wooden board used for playing the carrom game, where players use a striker to hit small disks called carrom men into corner pockets.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner-box">
              <div className="image-acti">
                <img src={bat} alt="" />
              </div>
              <div className="lower-contant">
                <div className="text-two">OutDoor Game</div>
                <h5>Badminton </h5>
                <div className="text-three">
                Badminton is a fast-paced racquet sport played on a rectangular court, where players hit a shuttlecock back and forth over a net, aiming to score points by landing it in the opponent's half
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="inner-box">
              <div className="image-acti">
                <img src={foosball} alt="" />
              </div>
              <div className="lower-contant">
                <div className="text-two">OutDoor Game</div>
                <h5>Foosball </h5>
                <div className="text-three">
                Foosball, also known as table soccer, is a tabletop game that simulates soccer by using rods to control miniature players to score goals against an opponent..
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  
  </div>
 <Footer/>
      </>
  )
}

export default Activities
