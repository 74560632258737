import React from 'react'
import { FaWifi } from "react-icons/fa";
import { PiTelevisionSimple } from "react-icons/pi";
import { GiBathtub } from "react-icons/gi";
import { FaGamepad } from "react-icons/fa";
import { CiParking1 } from "react-icons/ci";
import { FaSwimmingPool } from "react-icons/fa";




function Amenities() {
  return (
    <>
      <section className="amenities-section light-bg mx-60 border-shape-bottom">
  <div className="auto-container">
    <div className="title-box text-center">
      <div className="sub-title">amenities</div>
      <h2 className="sec-title">Make Your Stay Memorable</h2>
    </div>
    <div className="row">
      <div className="col-lg-4 amenities-block">
        <div className="inner-box">
          <div className="icon">
            <i className="flaticon-wifi-signal"><FaWifi /></i>
          </div>
          <h1>High Speed Wifi</h1>
          <div className="text">
          High-speed WiFi enables rapid internet access and seamless connectivity for streaming, gaming, and multiple device usage.
          </div>
        </div>
      </div>
      <div className="col-lg-4 amenities-block">
        <div className="inner-box">
          <div className="icon">
            <i className="flaticon-car"><CiParking1 /></i>
          </div>
          <h1>Parking</h1>
          <div className="text">
          Parking refers to the act of stopping and leaving a vehicle in a designated area temporarily
          </div>
        </div>
      </div>
      <div className="col-lg-4 amenities-block">
        <div className="inner-box">
          <div className="icon">
            <i className="flaticon-smart-tv"><PiTelevisionSimple /></i>
          </div>
          <h1>Smart TV</h1>
          <div className="text">
          A smart TV is an internet-connected television that offers a wide range of online features and apps for streaming content, browsing, and more.
          </div>
        </div>
      </div>
      <div className="col-lg-4 amenities-block">
        <div className="inner-box">
          <div className="icon">
            <i className="flaticon-pool"><FaSwimmingPool /></i>
          </div>
          <h1>Swimming Pool</h1>
          <div className="text">
          A swimming pool is a man-made basin filled with water, designed for swimming, diving, and recreational activities.
          </div>
        </div>
      </div>
      <div className="col-lg-4 amenities-block">
        <div className="inner-box">
          <div className="icon">
            <i className="flaticon-breakfast"><FaGamepad /></i>
          </div>
          <h1>Game</h1>
          <div className="text">
          Games are interactive forms of entertainment that engage players through challenges, storytelling, and strategic thinking.
          </div>
        </div>
      </div>
      <div className="col-lg-4 amenities-block">
        <div className="inner-box">
          <div className="icon">
            <i className="flaticon-bath"><GiBathtub /></i>
          </div>
          <h1> Bathtub</h1>
          <div className="text">
          A bathtub is a large, typically rectangular container used for holding water in which a person can bathe
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Amenities
