
import { Routes, Route} from 'react-router-dom';
import './App.css';
import Navi from './Components/Hader/Navi';
import Home from './Components/Home/Home';
import AboutPage from './Pages/AboutPage';
import Activities from './Pages/Activities';
import ContactPage from './Pages/ContactPage';
import GalleryP from './Components/Galleryp/Galleryp';
import AccomodationP from './Pages/AccomodationP';
import AttractionPage from './Components/AttractionPage/AttractionPage';

function App() {
  return (
    <>
    <Navi/>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/aboutpage' element={<AboutPage/>}/>
      <Route path='/activities' element={<Activities/>}/>
      {/* <Route path='/accomodation' element={<Accomodation/>}/> */}
      <Route path='/gallery' element={<GalleryP/>}/>
      <Route path='/contactpage' element={<ContactPage/>}/>
      <Route path='/rooms' element={<AccomodationP/>}/>
      <Route path='/attrac' element={<AttractionPage/>}/>
    </Routes>
   
   
 {/* <Counter/> */}
    
    </>
  );
}

export default App;
