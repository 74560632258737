import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import testimonial from "../../Assets/garden-flower.jpg"
import { Autoplay,} from 'swiper/modules';
import Counter from '../Counter/Counter';

function Testimonials() {
  return (
    <>
      <section className="testimonials-section light-bg mx-60 border-shape-top">
  <div className="auto-container">
    <div className="row">
      <div className="col-lg-7">
        <div
          className="content-block"
          style={{
            backgroundImage: 'url("'+testimonial+'")'
          }}
        >
          <div className="title-box text-light">
            <div className="sub-title">Testimonials</div>
            <h2 className="sec-title">Clients Review</h2>
          </div>
          <div className="link-btn">
            <a href="testimonials.html" className="theme-btn btn-style-one">
              <span>all testimonials</span>
            </a>
          </div>
          <div
            className="award"
            data-parallax='{"y": 20}'
            style={{
              transform:
                "translate3d(0px, 11.52px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)",
              WebkitTransform:
                "translate3d(0px, 11.52px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)"
            }}
          >
            <img src="assets/images/icons/icon-1.png" alt="" />
            <h4>
              awarded <br /> restaurant
            </h4>
          </div>
        </div>
      </div>
      <div className="col-lg-5">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="owl-item" >
                <div className="testimonial-block">
                  <h3>
                    <span className="quote">“</span>The Best Villa
                  </h3>
                  <div className="text">
                  Very nice place to stay with you friends and family too. Location is on 5 min drive from main road still Peaceful. Clean and specious 4 BHK villa with private pool in good affordable price. We went there with whole family and we loved our stay. Thank you!!
                  </div>
                  <div className="author-info">
                    <div className="author-thumb">
                    </div>
                    <div className="name">Sanchali Phadnis</div>
                  </div>
                </div>
              </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="owl-item" >
                <div className="testimonial-block">
                  <h3>
                    <span className="quote">“</span>Best Food Quality
                  </h3>
                  <div className="text">
                  Fantastic experience! The villa exceeded our expectations with its top-notch quality and exceptional cuisine. Every meal was a gourmet delight, perfectly complementing the luxurious and cozy ambiance. Ideal for couples seeking a romantic retreat with excellent food. Highly recommend
                  </div>
                  <div className="author-info">
                    <div className="author-thumb">
                      <img src="assets/images/resource/image-2.png" alt="" />
                    </div>
                    <div className="name">Pramodini K</div>
                  </div>
                </div>
              </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="owl-item" >
                <div className="testimonial-block">
                  <h3>
                    <span className="quote">“</span>Couple Friendly
                  </h3>
                  <div className="text">
                  Absolutely enchanting! This villa is a romantic haven, offering breathtaking views, serene ambiance, and modern amenities. Perfect for couples, its cozy setting ensures privacy and intimacy. A flawless escape that rejuvenates and delights. Highly recommend for a dreamy getaway!
                  </div>
                  <div className="author-info">
                    <div className="author-thumb">
                      <img src="assets/images/resource/image-2.png" alt="" />
                    </div>
                    <div className="name">Mallepally Naveenreddy</div>
                  </div>
                </div>
              </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="owl-item" >
                <div className="testimonial-block">
                  <h3>
                    <span className="quote">“</span>Familiar Staff
                  </h3>
                  <div className="text">
                  Cheap rates. Good for Batchelors. Property looks like WIP. Internal electric n  other fittings are faulty. Not a great service n amenities. They didn't care to put hand wash or soap in our rooms. Avg food but descent breakfast. Don't recommend for families.
                  </div>
                  <div className="author-info">
                    <div className="author-thumb">
                      <img src="assets/images/resource/image-2.png" alt="" />
                    </div>
                    <div className="name">Sarang Brahme</div>
                  </div>
                </div>
              </div>
        </SwiperSlide>
        
      </Swiper>
        <div
          className="theme_carousel owl-theme owl-carousel owl-dot-style-one owl-loaded owl-drag"
          data-options='{"loop": true, "center": false, "margin": 30, "autoheight":true, "lazyload":true, "nav": true, "dots": true, "autoplay": true, "autoplayTimeout": 6000, "smartSpeed": 1000, "responsive":{ "0" :{ "items": "1" }, "480" :{ "items" : "1" }, "600" :{ "items" : "1" }, "768" :{ "items" : "1" } , "992":{ "items" : "1" }, "1200":{ "items" : "1" }}}'
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(-948px, 0px, 0px)",
                transition: "all 1s ease 0s",
                width: 3318
              }}
            >
           
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>

 <Counter/>
</section>

    </>
  )
}

export default Testimonials
