import aboutpagebanner from "../Assets/terris view.jpg"
import Amenities from "../Components/AMENITIES/Amenities"
import { Accordion } from "react-bootstrap"
import roomw from "../Assets/Rooms/4-bedroom.jpeg"
import roomw1 from "../Assets/Rooms/20230603_160611.jpg"
import roomw2 from "../Assets/Rooms/5-bedroom.jpeg"
import roomw3 from "../Assets/Rooms/bed-down-view.jpg"
import roomw4 from "../Assets/Rooms/bed.jpg"
import roomw5 from "../Assets/prime-room.jpg"
import Footer from "../Components/Footer/Footer"

function AccomodationP() {
  return (
    <>

   <div className="hide-dd">
   Nestled in the picturesque hills of Maharashtra, a villa with luxurious rooms in Lonavala offers a perfect escape from the hustle and bustle of city life. Known for its lush greenery, serene lakes, and misty mountains, Lonavala is an ideal destination for those seeking tranquility and comfort. This villa is designed to provide an unparalleled experience with its opulent rooms and top-notch amenities.
Each room in the villa is meticulously decorated, blending modern elegance with traditional charm. The spacious interiors are adorned with plush furnishings, ensuring that guests enjoy the utmost comfort. Large windows in every room allow natural light to flood in, offering breathtaking views of the surrounding landscape. Whether you are lounging in the king-sized bed or relaxing in the cozy seating area, the ambiance of a villa with luxurious rooms in Lonavala is sure to captivate you.
The villa features several types of rooms to cater to different needs, including deluxe rooms, suites, and family rooms. Each room is equipped with state-of-the-art facilities such as high-speed Wi-Fi, flat-screen televisions, and air conditioning. The en-suite bathrooms are a highlight, boasting rain showers, marble finishes, and premium toiletries, providing a spa-like experience within the comfort of your room.
Outside the rooms, the villa offers a range of amenities to enhance your stay. Guests can take a dip in the private pool, unwind in the lush garden, or enjoy a meal prepared by a personal chef in the dining area. The villa also provides easy access to local attractions, making it convenient for guests to explore the beauty of Lonavala.
In summary, a villa with luxurious rooms in Lonavala promises a blend of luxury and serenity. Whether you are planning a romantic getaway, a family vacation, or a retreat with friends, this villa ensures an unforgettable experience with its lavish accommodations and stunning surroundings.

   </div>

    <section
      className="page-title"
      style={{
       backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.5), 
        rgba(0, 0, 0, 0.5)
      ), url("${aboutpagebanner}")`,
      }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Room</h1>
        </div>
      </div>
    </section> 
  
   


  <section className="activities-section light-bg mx-60 border-shape-top">
    <div className="container">   
       <div className="row">
       <div className="title-box">
            <div className="sub-title">Room</div>
            <h2 className="sec-title">
            Elegant 4 BHK villa with luxury. 
            </h2>
          </div>
        <div className="col-lg-12">
          <div className="dietails-rooms">
            <p> <span className="span-effect">Nestled in the picturesque hills of Maharashtra</span>  a villa with luxurious rooms in Lonavala offers a perfect escape from the hustle and bustle of city life. Known for its lush greenery, serene lakes, and misty mountains, Lonavala is an ideal destination for those seeking tranquility and comfort. This villa is designed to provide an unparalleled experience with its opulent rooms and top-notch amenities.</p>
            <p>Each room in the villa is meticulously decorated, blending modern elegance with traditional charm. The spacious interiors are adorned with plush furnishings, ensuring that guests enjoy the utmost comfort. Large windows in every room allow natural light to flood in, offering breathtaking views of the surrounding landscape. Whether you are lounging in the king-sized bed or relaxing in the cozy seating area, the ambiance of a villa with luxurious rooms in Lonavala is sure to captivate you.</p>
            <p>The villa features several types of rooms to cater to different needs, including deluxe rooms, suites, and family rooms. Each room is equipped with state-of-the-art facilities such as high-speed Wi-Fi, flat-screen televisions, and air conditioning. The en-suite bathrooms are a highlight, boasting rain showers, marble finishes, and premium toiletries, providing a spa-like experience within the comfort of your room.</p>
            <p>Outside the rooms, the villa offers a range of amenities to enhance your stay. Guests can take a dip in the private pool, unwind in the lush garden, or enjoy a meal prepared by a personal chef in the dining area. The villa also provides easy access to local attractions, making it convenient for guests to explore the beauty of Lonavala.</p>
          </div>
            <p>In summary, a villa with luxurious rooms in Lonavala promises a blend of luxury and serenity. Whether you are planning a romantic getaway, a family vacation, or a retreat with friends, this villa ensures an unforgettable experience with its lavish accommodations and stunning surroundings.</p>
         
        </div>
       </div>

        <div className="row">
      <div className="col-lg-12">
        <div
          className="cat_section_headings wow zoom"
          style={{ visibility: "visible", animationName: "zoom" }}
        >
          {/* <span className="cat_subheading">Who we are</span> */}
          <div className="our-gall">

          </div>
          <div className="cat_border" />
        </div>
      </div>
    </div>
        {/* <h5 className='view-gall'> <a href='/gallery-page'>View more</a></h5> */}
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="banq-imq">
                <img src={roomw} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="banq-imq">
                 <img src={roomw1} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="banq-imq">
              <img src={roomw2} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="banq-imq">
                <img src={roomw3} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="banq-imq">
                <img src={roomw4} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <div className="banq-imq  bot">
              <img src={roomw5} alt="" />
              </div>
            </div>
          </div>
        </div>
</section>
<Amenities/>
<Footer/>
     </>
  
  )
}

export default AccomodationP
