import React from 'react'
import Footer from '../Footer/Footer'
import { Fade } from 'react-awesome-reveal';
import "./AttractionPage.css"
import aboutpagebanner from "../../Assets/mountain-view.jpg"
import visa from "../../Assets/Attraction/visapur-img.webp"
import bhaja from "../../Assets/Attraction/bhaja.jpg"
import loha from "../../Assets/Attraction/loha.jpg"
function AttractionPage() {
  return (
    <>
  <section
      className="page-title"
      style={{
       backgroundImage: `linear-gradient(
        rgba(0, 0, 0, 0.5), 
        rgba(0, 0, 0, 0.5)
      ), url("${aboutpagebanner}")`,
      }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Nearby Attraction</h1>
        </div>
      </div>
    </section> 
    <section className="activities-section light-bg mx-60 border-shape-top">
      
        <div className="container">
          <div className="row">
            <div className="col-lg-6 sm-2-sm-12">
              <Fade direction="left">
                <div className="event-image">
                  <img src={visa} />
                </div>
              </Fade>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="titleSection">
                <h2 className="roomText">Visapur Fort</h2>
                <div className="birthdaydetail">
                  <p>
                  Visapur Fort, a historical hill fort near Lonavala in Maharashtra, offers breathtaking views and a glimpse into the region's rich past. Located close to the popular tourist destination of Lonavala, Visapur Fort is a favored spot for trekkers and history enthusiasts. The fort's scenic beauty and serene surroundings make it an ideal escape from the hustle and bustle of city life. If you're planning a visit, staying at a Villa in Lonavala can enhance your experience, providing a comfortable base to explore the fort.
                  </p>  
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12 order-md-1 order-2">
              <div className="titleSection">
                <h2>Bhaja caves</h2>
                <div className="birthdaydetail">
                 <p> 
                 Bhaja Caves, an ancient Buddhist rock-cut cave complex near Lonavala, offers a fascinating glimpse into India's rich history. These caves, known for their intricate carvings and stupas, attract history buffs and tourists alike. To fully enjoy the serene beauty and historical significance of Bhaja Caves, staying at a Villa in Lonavala is highly recommended. This accommodation choice ensures you can immerse yourself in the tranquility and scenic surroundings, making your visit to Bhaja Caves a memorable experience.

</p>
                </div>
           </div>
            </div>
            <div className="col-lg-6 col-sm-12 order-md-2 order-1">
              <Fade direction="right">
                <div className="event-image">
                <img src={bhaja}alt="Farmhouse for Events" />
                </div>
              </Fade>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12 order-md-1 order-22">
              <Fade direction="left">
                <div className="event-image">
                  <img src={loha} alt=" Events in Wada" />
                </div>
              </Fade>
            </div>
            <div className="col-lg-6 col-sm-12 order-md-2 order-1">
              <div className="titleSection">
                <h2>Tikona Fort</h2>
                <div className="birthdaydetail">
                  <p>
                  Tikona Fort, also known as Vitandgad, is a prominent hill fort located near Lonavala in Maharashtra. Its triangular shape gives it the name "Tikona," meaning "triangle." The fort offers stunning panoramic views of the surrounding valleys and the Pavana Dam. For those visiting the fort, staying in a Villa in Lonavala can enhance the experience, providing a luxurious and comfortable base. The serene and picturesque environment of Lonavala, combined with the historical significance of Tikona Fort, makes for an unforgettable trip. Exploring the fort's ancient architecture and enjoying the modern amenities of a villa creates a perfect blend of adventure and relaxation.
                   </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-sm-12 order-md-1 order-2">
              <div className="titleSection">
                <h2>Lohagadh Fort</h2>
                <div className="birthdaydetail">
                 <p>
                 Lohagad Fort, perched at an elevation of 3,389 feet, is a historical marvel near Lonavala, Maharashtra. Known for its sturdy architecture and panoramic views, Lohagad was a significant stronghold during the Maratha Empire. Visitors can explore ancient gates, bastions, and intricate carvings that tell tales of the past. A stay in a villa in Lonavala offers a perfect complement to this adventure, providing luxurious accommodation amidst nature. After a day of trekking and exploring Lohagad Fort, retreating to a comfortable place in Lonavala ensures a restful and serene experience, blending history, adventure, and relaxation seamlessly.
                 </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 order-md-2 order-1">
              <Fade direction="right">
                <div className="event-image order-md-2 order-1">
                  <img src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0c/d3/55/5a/pathway-to-fort.jpg?w=500&h=-1&s=1" alt=" Events in Wada" />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </>
  )
}

export default AttractionPage
