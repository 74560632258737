import React, { useRef, useState } from 'react';
import { FaPlayCircle } from "react-icons/fa";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./banner.css"


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import banner1 from "../../Assets/sky-view-2.jpg"
import banner2 from "../../Assets/privet-parking.jpg"
import { light } from '@mui/material/styles/createPalette';
function Banner() {
  return (
   <>
   <section className="banner-section">
  <div>
    <div
      className="swiper-wrapper"
    //   style={{
    //     transitionDuration: "0ms",
    //     transform: "translate3d(-2048px, 0px, 0px)"
    //   }}
    >

<Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
      >
        <SwiperSlide>
        <div
        className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
         
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${banner1}")`,
        width: 1800,
        height: 700 // Example height
      }}
    
        data-swiper-slide-index={2}
      >
        <div className="content-outer">
          <div className="content-box">
            <div className="inner">
              <h1 className="banner-title">
                The Creeks Of <br /> Luxury In Nature
              </h1>
              <div className="text">
               A lavish retreat offering unparalleled comfort amidst pristine wilderness.
              </div>
             
            </div>
          </div>
        </div>
      </div>
        </SwiperSlide>
        <SwiperSlide>
        <div
        className="swiper-slide swiper-slide-prev"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("${banner2}")`,
          width: 1800,
          height: 700 // Example height
        }}
        data-swiper-slide-index={0}
      >
        <div className="content-outer">
          <div className="content-box">
            <div className="inner">
              <h1 className="banner-title">
                World Best Resort <br /> In Wild Nature
              </h1>
              <div className="text">
              Luxurious haven nestled in untamed nature, <br /> offering unparalleled serenity and world-class amenities.
              </div>
              
            </div>
          </div>
        </div>
      </div>
        </SwiperSlide>
       
      
      </Swiper>
      
      {/* Slide Item */}
      
      {/* Slide Item */}
    
      {/* Slide Item */}
     
      
    </div>
    <span
      className="swiper-notification"
      aria-live="assertive"
      aria-atomic="true"
    />
  </div>
 
</section>

   </>
  )
}

export default Banner
