import React, { useState } from "react";
import "./hAbout.css";
import view from "../../Assets/parking-.jpg";
import { Link, NavLink } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";

function HomeAbout() {
  const [showAllParagraphs, setShowAllParagraphs] = useState(false);

  const toggleParagraphs = () => {
    setShowAllParagraphs(!showAllParagraphs);
  };

  return (
    <>
      <section className="about-us-section light-bg mx-60 border-shape-top">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-6">
              <div className="content-block">
                <div className="title-box">
                  <div className="sub-title">about us</div>
                  <h2 className="sec-title">
                    We Invite guests to <br /> celebrate life
                  </h2>
                </div>
                {showAllParagraphs ? (
                  <>
                    <div className="text">
                      Nestled amidst the scenic Sahyadri hills, our luxury villa
                      in Lonavala invites guests to celebrate life in the most
                      enchanting way. This haven of tranquillity and elegance is
                      designed to offer an unparalleled experience, blending
                      nature's beauty with the finest comforts. Whether you're
                      planning a family getaway, a romantic escape, or a joyous
                      gathering with friends, our Luxury Villa in Lonavala
                      promises to make every moment memorable. Upon entering the
                      home, you are welcomed by a tasteful fusion of modern
                      style and classic elegance. Spacious living areas,
                      tastefully decorated rooms, and state-of-the-art amenities
                      ensure that you and your guests can relax and indulge in
                      luxury. The villa boasts multiple bedrooms with en-suite
                      bathrooms, a fully equipped kitchen, and expansive outdoor
                      spaces perfect for hosting celebrations or simply
                      unwinding
                    </div>
                    <div className="text">
                      One of the standout features of our luxury villa in
                      Lonavala is its private infinity pool, offering
                      breathtaking views of the surrounding hills and valleys.
                      Imagine lounging by the pool with a refreshing drink in
                      hand, watching the sunset paint the sky in hues of orange
                      and pink. The lush gardens and serene environment create
                      an idyllic backdrop for any event, be it a birthday party,
                      an anniversary celebration, or an intimate wedding
                      ceremony
                    </div>
                    <div className="text">
                      Our dedicated staff is committed to providing impeccable
                      service, ensuring that your stay is as comfortable and
                      enjoyable as possible. From personalized concierge
                      services to gourmet dining experiences, we cater to your
                      every need.
                    </div>
                    <div className="text">
                      Celebrate life’s special moments in style and
                      sophistication at our luxury villa in Lonavala. Here,
                      every detail is thoughtfully curated to create an
                      atmosphere of joy and celebration, allowing you to make
                      lasting memories with your loved ones. Whether it's a
                      quiet retreat or a grand celebration, our villa is the
                      perfect destination to embrace the essence of luxury and
                      tranquillity.
                    </div>
                  </>
                ) : (
                  <div className="text">
                    Nestled amidst the scenic Sahyadri hills, our luxury villa
                    in Lonavala invites guests to celebrate life in the most
                    enchanting way. This haven of tranquillity and elegance is
                    designed to offer an unparalleled experience, blending
                    nature's beauty with the finest comforts. Whether you're
                    planning a family getaway, a romantic escape, or a joyous
                    gathering with friends, our Luxury Villa in Lonavala
                    promises to make every moment memorable.
                  </div>
                )}
                <div className="text">
                  <div className="buttonGroup">
                    <button className="readButton" onClick={toggleParagraphs}>{showAllParagraphs ? <FaAngleUp /> : <FaAngleDown />}&nbsp;Read {showAllParagraphs ? "Less" : "More"}</button>
                    <NavLink to="/aboutpage"><button className="viewButton">Know More</button></NavLink>
                  </div>
                </div>
                <div className="signature">
                  <img src="assets/images/resource/image-4.png" alt="" />
                </div>

                <div
                  className="award"
                  data-parallax='{"x": 20}'
                  style={{
                    transform:
                      "translate3d(7.741px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)",
                    WebkitTransform:
                      "translate3d(7.741px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)",
                  }}
                >
                  <div className="icon">
                    <img src="assets/images/icons/icon-1.png" alt="" />
                  </div>
                  <h4>
                    awarded <br /> Resort
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image img_hover_3">
                <img src={view} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeAbout;
